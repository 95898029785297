import React, { useState } from 'react';

function InscriptionChecker() {
    const [address_id, setInscriptionId] = useState('');
    const [runes, setRunes] = useState(null);
    const [stones, setStones] = useState(null); // State to store 'stones' data
	const [thetop, setTop] = useState(null);
    const [error, setError] = useState('');

    const fetchRunes = async () => {
        if (!address_id) {
            setError('Please enter a Bitcoin address');
            setRunes(null);
            setStones(null);  // Reset stones when there's no address
			setTop(null);
            return;
        }

        try {
            const response = await fetch(`/api/data/${address_id}`,{
                method: 'GET',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
		    setRunes(data[0].runes);
            setStones(data[0].stones); // Set the stones data from the response
			setTop(Number(data[0].thetop.toFixed(2))); 
            console.log('result:', data);
            setError('');
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to fetch data');
            setRunes(null);
            setStones(null);  // Reset stones on error
			setTop(null);
        }
    };

    return (
        <div>
            <h1>Runestones Loyalty Checker</h1>
            <input
                type="text"
                value={address_id}
                onChange={e => setInscriptionId(e.target.value)}
                placeholder="Enter Wallet Address to check Loyalty"
            />
            <button onClick={fetchRunes}>Check</button>
            {runes !== null && <p>Whisperer Blocks held: {runes}. </p>}
			{thetop !== null && <p>You are in the top {thetop}% of Whisperers holders.</p>}
			{stones !== null && <p>Runestones held: {stones}</p>}
			{runes !== null && stones !== null && <p>Your Total Score is: {stones > 0 ? runes * 2 : runes}</p>}

            {error && <p>Error: {error}</p>}
        </div>
    );
}

export default InscriptionChecker;
