import React, { useState } from 'react';
import InscriptionChecker from './InscriptionChecker';
import Leaderboard from './Leaderboard';
import CountdownTimer from './CountdownTimer';
import CSVTable from './CSVTable';
//import './AppStyles.css'; // Importing CSS for styling buttons

function App() {
    const [showLeaderboard, setShowLeaderboard] = useState(true);

    return (
        <div className="App">
            <br/>
            <br/>
            <InscriptionChecker />
            <br/>
            {/* Button group for navigation buttons */}
            <div className="button-group" style={{ textAlign: 'center', marginBottom: '20px' }}>
                <button 
                    onClick={() => setShowLeaderboard(false)}
                    className={!showLeaderboard ? "button active" : "button"}>
                    Runestones Leaderboard
                </button>
				<button 
                    onClick={() => setShowLeaderboard(true)}
                    className={showLeaderboard ? "button active" : "button"}>
                    Whisperers Leaderboard
                </button>
            </div>
            <br/>
            {showLeaderboard ? <Leaderboard /> : <CSVTable />}
			 <div className="faq-section">
                <h2>FAQ</h2>
                <h3>How is my loyalty score calculated?</h3>
                <p>A wallet's loyalty score is calculated based on how many blocks they have held their runes Ordinal for. The more items you own, the more points you earn! If you hold at least 1 Runestone, your points are multiplied by 2. If you transfer the runes Ordinal, you lose your points.</p>
                <h3>What will this be used for?</h3>
                <p>These scores can be used by projects launching on runes who want to distribute their tokens to the highest scoring diamond hands.</p>
                <h3>What are Rune Whisperers?</h3>
                <p>Rune Whisperers is the first 10k pfp collection for Runestone Holders. We are the creators of the loyalty checker and aim to continue helping builders in the runes ecosystem.</p>
                <h3>Do I have to connect my wallet?</h3>
                <p>No you do not have to connect your wallet. Just paste the address holding the NFT’s into the search bar.</p>
				<h3>What is the source of this data?</h3>
                <p>Rune Whisperers data is extracted from on-chain and calculated by us. Runestones data is provided by TheWizardsOfOrd. </p>
				
            </div>
            <footer className="footer">
				<a href="https://twitter.com/Rune_Whisperers" className="social-link" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-twitter"></i>
                </a>
                <a href="https://discord.gg/X7Dfa3UH" className="social-link" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-discord"></i>
                </a>
            </footer>									   																		
        </div>
    );
}

export default App;
