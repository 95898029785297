import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';

const CSVTable = () => {
    const [data, setData] = useState([]);
    const [visibleData, setVisibleData] = useState([]);
																													 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/api/toprunestone', {
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY
                    }
                });
                if (!response.ok) throw new Error('Failed to fetch data');
                const data = await response.json();
                setData(data);
                setVisibleData(data.slice(0, 100)); // Assume the data is already in the correct format
            } catch (error) {
                console.error("Error fetching data:", error);
            }																					   
        };

        fetchData();
    }, []);

	const handleDownload = async () => {
		try {
			const response = await fetch("https://raw.githubusercontent.com/TheWizardsOfOrd/runestones_wizards_snapshot/main/snapshot.csv");
			if (!response.ok) throw new Error('Failed to fetch CSV');

			// Process the stream
			const reader = response.body.getReader();
			let receivedLength = 0; // length of received binary data
			let chunks = []; // array of received binary chunks (comprises the body)
			while (true) {
				const { done, value } = await reader.read();
				if (done) {
					break;
				}
				chunks.push(value);
				receivedLength += value.length;
			}
			// Concatenate chunks into single Uint8Array
			let chunksAll = new Uint8Array(receivedLength);
			let position = 0;
			for (let chunk of chunks) {
				chunksAll.set(chunk, position);
				position += chunk.length;
			}
			// Decode Uint8Array into string
			const decoder = new TextDecoder('utf-8');
			const csv = decoder.decode(chunksAll);
			// Trigger download
			downloadCSV(csv);
		} catch (error) {
			console.error("Error downloading CSV:", error);
		}
	};

	const downloadCSV = (csv) => {
		const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = "snapshot.csv";
		link.click();
	};


    return (
        <div>
            <h2 className="mb-4 text-center">Runestones Leaderboard - top 100 Hodlers</h2>
			<button className="btn btn-primary" onClick={handleDownload}>Download Runestone Snapshot</button>
			<br/>
            <table className="table">
                <thead>
                    <tr>
                        <th>RANK</th>
                        <th>ADDRESS</th>
                        <th>RUNESTONES HELD</th>
                    </tr>
                </thead>
                <tbody>
                    {visibleData.map((row, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td> {/* Assuming rank should still be calculated */}
                            <td>{row.address}</td>
                            <td>{row.count}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            
        </div>
    );
};

export default CSVTable;
