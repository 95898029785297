import React, { useState, useEffect } from 'react';
import './Leaderboard.css'; // Assuming you have additional custom styles in Leaderboard.css



function convertToCSV(objArray) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    // Create headers
    str += "Rank,Address,LoyaltyScore,Commitment Score\r\n";

    // Loop over the array of objects and for each object turn the values into a comma separated string.
    array.forEach((item, index) => {
        const rank = index + 1; // assuming rank is simply the item's index + 1
        str += `${rank},${item.address},${item.runes},${item.total_inscriptions}\r\n`;
    });

    return str;
}


function downloadCSV(csv, filename) {
    const csvFile = new Blob([csv], {type: "text/csv"});
    const downloadLink = document.createElement("a");

    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";

    document.body.appendChild(downloadLink);
    downloadLink.click();
}


function Leaderboard() {
    const [leaders, setLeaders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(100);
    const [error, setError] = useState('');

    const fetchLeaderboard = async () => {
        try {
            const response = await fetch('/api/leaderboard',{
													method: 'GET',
													headers: {
														'x-api-key': process.env.REACT_APP_API_KEY
													}
										});
            if (!response.ok) {
                throw new Error('Failed to fetch leaderboard data');
            }
            const data = await response.json();
            setLeaders(data);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleDownload = () => {
        const csv = convertToCSV(leaders);
        downloadCSV(csv, "leaderboard.csv");
    };

    useEffect(() => {
        fetchLeaderboard();
        /*const interval = setInterval(() => {
            fetchLeaderboard();  // Refresh data every 5 minutes
        }, 100000); // 100000 milliseconds = 5/3 minutes

        return () => clearInterval(interval); // Cleanup the interval on component unmount*/
    }, []);
	
	
    const getStars = (total) => {
        const num = parseInt(total);
        if (num >= 101) return '★★★★★';
        else if (num >= 51) return '★★★★';
        else if (num >= 10) return '★★★';
        else if (num >= 2) return '★★';
        else if (num >= 1) return '★';
        return '';
    };
    // Calculate pagination details
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = leaders.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="container mt-5">
            <h2 className="mb-4 text-center">Whisperers Leaderboard: top 100 Diamond Hands</h2>
             <div className="table-responsive">
			    <button className="btn btn-primary" onClick={handleDownload}>Download Snapshot</button>
				<br/>
				<table className="table table-dark table-striped">
					<thead>
						<tr>
							<th>RANK</th>
							<th>ADDRESS</th>
							<th>BLOCKS HELD</th>
							<th>COMMITMENT SCORE</th>
						</tr>
					</thead>
					<tbody>
						{currentItems.map((leader, index) => (
							<tr key={index}>
								<td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
								<td>{leader.address}</td>
								<td>{leader.runes}</td>
								<td>{getStars(leader.total_inscriptions)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>	
            {error && <div>Error: {error}</div>}
			<br/>
			<h4>Snapshot is updated every 24 hours.</h4>
			<br/>
			<br/>
        </div>
    );
}

function Pagination({ itemsPerPage, totalItems, paginate, currentPage }) {
    const pageNumbers = [];
    const [visiblePages, setVisiblePages] = useState([]);

    // Calculate total pages
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // Fill page numbers array
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        // Determine the start and end index of visible pages
        let start = currentPage - 5 < 1 ? 1 : currentPage - 5;
        let end = currentPage + 4 > totalPages ? totalPages : currentPage + 4;
        
        // Adjust if approaching start or end of page list
        if (currentPage < 6) {
            end = 10 < totalPages ? 10 : totalPages;
        }
        if (currentPage > totalPages - 5) {
            start = totalPages - 9 > 1 ? totalPages - 9 : 1;
        }

        // Slice the page numbers to show only a subset
        setVisiblePages(pageNumbers.slice(start - 1, end));
    }, [currentPage, totalPages]);

    return (
        <nav>
            <ul className='pagination'>
                {currentPage > 1 && (
                    <li className='page-item'>
                        <a onClick={() => paginate(currentPage - 1)} className='page-link'>
                            &laquo; Previous
                        </a>
                    </li>
                )}
                {visiblePages.map(number => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <a onClick={() => paginate(number)} className='page-link'>
                            {number}
                        </a>
                    </li>
                ))}
                {currentPage < totalPages && (
                    <li className='page-item'>
                        <a onClick={() => paginate(currentPage + 1)} className='page-link'>
                            Next &raquo;
                        </a>
                    </li>
                )}
            </ul>
        </nav>
    );
}


export default Leaderboard;
